#header-intro {
  min-height: 30vh;
  background: #025bff;
  padding-bottom: 10%;
}

#header-intro *{
  color: #fff;
}

#content .container {
  max-width: 600px;
  padding-top: 110px;
  padding-bottom: 40px;
}

.car-ad {
  position: absolute;
  bottom: -110px;
  left: 25%;
}

.btn-register {
  border-radius: 0px;
  background-color: #025bff;
  font-size: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.input-group-text {
  background: none;
}

.text-left {
  text-align: left !important;
  font-weight: bold;
}

.text-right {
  text-align: right !important;
}

.required {
  color: red;
}